import React, { useState, useEffect, useContext } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import mobile from 'is-mobile';
import api from '~/services/api';
import { GeneralContext } from '~/contexts';

const PrivateRoute: React.ReactType = ({ component: Component, ...rest }) => {
    const history                  = useHistory();
    const { logged, logout, user } = useContext(GeneralContext);
    const { role, token }          = user;
    const [ready, setReady]        = useState<Boolean>();
    const [auth, setAuth]          = useState<Boolean>();

    useEffect(() => {
        if (!logged) {
            history.push('/login')
        } else {
            api.post('validateToken', { token }).then(resp => {
                const { valid } = resp.data;
                setAuth(valid);
                setReady(true);
                if (!valid) logout();
            }).catch(error => {
                console.log(error);
            });
        }
    }, [user, auth]);

    if (!auth && !ready) {
        return (<></>);
    }

    return (
        <Route {...rest} render={
            props => {
                if (auth) {
                    if (rest.middleware && (rest.middleware!==role || mobile())) {
                        return <Redirect to={{ pathname: '/404', state: { from: props.location } }} />
                    }

                    return <Component {...props}></Component>
                } else {
                    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                }
            }
        } />
    );
}

export default PrivateRoute;