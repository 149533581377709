import React, { useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import Modal from 'react-modal';
import './styles.css';

interface Props {
    status: any;
    title: string;
    close: Function;
    afterOpen?: Function;
    footer?: Element;
}

const ModalBox: React.FC<Props> = (props) => {
    useEffect(() => {
        Modal.setAppElement('body');
    }, []);

    function closeModal(){
        props.close(false);
    }

    function afterOpenModal() {
        if (props.afterOpen)
            props.afterOpen();
    }

    return (
        <Modal
            isOpen={props.status}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="userModal"
            className="modal"
            ariaHideApp={false}
        >
            <header className="modal-header">
                <h3>{ props.title }</h3>

                <button onClick={closeModal}>
                    <FiX size="18" strokeWidth="4" />
                </button>
            </header>

            <section className="modal-body">
                { props.children }
            </section>

            <footer className="modal-footer">
                { props.footer ? props.footer : <></> }
            </footer>
        </Modal>
    )
}

export default ModalBox;