import React, { useContext, useEffect, useState } from 'react';
import ReactTooltip from "react-tooltip";
import { FaPlus, FaTimes } from 'react-icons/fa';
import { GeneralContext, ModalContext, PisCofinsContext, ProviderContext } from '~/contexts';
import './styles.css';

import api from '~/services/api';
import env from '~/utils/env';

// Import Components
import AdminDesign from '~/components/admin';
import GroupBox from '~/components/parts/GroupBox';
import {LoadingSingle} from '~/components/default/loading';

// Form
import { FormGroups, FormUnitsGroup } from '~/forms';

const Groups: React.FC<any> = ({ match }) => {
    const {setIsOpen, setTitleModal, setContentModal, setFooterModal} = useContext(ModalContext);
    const {user}                      = useContext(GeneralContext);
    const [ready, setReady]           = useState(false);
    const [groupsList, setGroupsList] = useState([]);
    const [groups, setGroups]         = useState([]);
    const [tooltip, setTooltip]       = useState('');
    const [search, setSearch]         = useState('');

    function openModal(modal: any, data?: any, type?: string) {
        switch (modal) {
            case 'createEditGroup':
                setTitleModal(type==='edit' ? 'Editando Grupo' : 'Criando Grupo');

                let dataGroup = {
                    id         : 0,
                    name       : '',
                    active     : 1,
                    errors     : null
                };

                if (data) {
                    dataGroup.id     = data.id;
                    dataGroup.name   = data.name;
                    dataGroup.active = !data.active ? 0 : 1;
                }

                setContentModal(<FormGroups setReady={setReady} data={dataGroup} />);
                break;

            case 'editUnitsGroup':
                setTitleModal('Editando Unidades do Grupo');
                setContentModal(<FormUnitsGroup setGroupReady={setReady} group={data} />);
                break;

            default:
                setTitleModal('');
                setContentModal(<></>);
                setFooterModal(<></>);
                break;
        }

        setIsOpen(true)
    }

    function onChangeSearch (event: any) {
        let data = event.target.value;
        let groupsFilter = groupsList.filter((el: any) =>
            el.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0 ||
            (el.nick && el.nick.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0)
            ? true : false
        )

        setSearch(data)
        setGroups(groupsFilter);
    }

    function deteleGroup (id: any) {
        api.delete(
            `${env.host}/ccee/groups/${id}`,
            {headers: { Authorization: user.token }}
        ).then(resp => {
            setReady(false)
        })
    }

    useEffect(() => {
        if (!ready) {
            api.get(
                `${env.host}/ccee/groups`,
                {headers: { Authorization: user.token }}
            ).then(resp => {
                setGroupsList(resp.data)
                setGroups(resp.data)
                setReady(true)
            })
        }
    }, [ready, groups, tooltip]);

    return (
        <AdminDesign pageTitle="Gerenciamento de Grupos - API Energizou" title="Grupos" subtitle="Gestão de Grupos" className="gp">
            {
                ready ? (
                    <>
                        <div className="headerLine">
                            <div className="buttons">
                                <button
                                    type="button"
                                    className="group"
                                    onClick={() => openModal('createEditGroup')}
                                    onMouseOver={() => setTooltip('Adicionar Grupo')}
                                    data-for="tooltip_groups"
                                    data-tip
                                >
                                    <FaPlus />
                                </button>
                            </div>

                            <div className="searchBox">
                                <input type="text" name="search" id="search" placeholder='Filtrar Grupos...' value={search} onChange={onChangeSearch} />
                                { search!=='' && <button onClick={() => {
                                    setSearch('')
                                    setGroups(groupsList)
                                }}>
                                    <FaTimes />
                                </button> }
                            </div>
                        </div>

                        <div className="groups">
                            { groups.map((el: any) => <GroupBox
                                key={`groups_${el.id}`}
                                edit={openModal}
                                delete={deteleGroup}
                                {...el}
                            />) }
                        </div>

                        <ReactTooltip
                            id="tooltip_groups"
                            place="bottom"
                            effect="solid"
                            type="info"
                        >
                            { tooltip }
                        </ReactTooltip>
                    </>
                ) : <LoadingSingle />
            }
        </AdminDesign>
    );
}

export default Groups;