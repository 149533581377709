import React, { ChangeEvent, useContext, useEffect } from 'react';
import { ModalContext, UserContext } from '~/contexts';
import './styles.css';

import user_image from '~/assets/images/user.jpg';

// Interface
const FormUser: React.FC<any> = ({setUsers}) => {
    const {setIsOpen, setFooterModal} = useContext(ModalContext);
    const {userData, setUserData, saveUser} = useContext(UserContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {name, value} = event.target;
        const data = {[name]: (name==='active' ? Number(value) : value)};

        setUserData({...userData, ...data});
    }

    useEffect(() => {
        setFooterModal(<>
            <button id="btnSave" type="button" onClick={() => { setIsOpen(false); saveUser(setUsers)}}>Salvar</button>
            <button id="btnCancel" type="button" onClick={() => setIsOpen(false)}>Cancelar</button>
        </>)
    }, [userData])

    return (
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editUser">
            <div className="imageBox">
                <img src={userData.image ? userData.image : user_image} alt={`Imagem do usuário ${userData.name}`} />
            </div>

            <hr />

            <div className="form-group">
                <div className="input-group">
                    <label htmlFor="name">Nome</label>
                    <input id="name" className={userData.errors && userData.errors.name ? 'error' : ''} name="name" type="text" placeholder="Nome" value={userData.name} onChange={onChange} />
                </div>

                <div className="input-group w220">
                    <label htmlFor="type">Tipo</label>
                    <select name="type" id="type" className={userData.errors && userData.errors.type ? 'error' : ''} onChange={onChange} value={userData.type}>
                        <option value="user">Usuário</option>
                        <option value="admin">Administrador</option>
                    </select>
                </div>

                <div className="input-group w140">
                    <label htmlFor="active">Status</label>
                    <select name="active" id="active" className={userData.errors && userData.errors.active ? 'error' : ''} onChange={onChange} value={userData.active}>
                        <option value="1">Ativo</option>
                        <option value="0">Inativo</option>
                    </select>
                </div>
            </div>

            <div className="input-group">
                <label htmlFor="mail">Email</label>
                <input id="mail" className={userData.errors && userData.errors.mail ? 'error' : ''} name="mail" type="mail" placeholder="Email" value={userData.mail} onChange={onChange} />
            </div>

            <hr />

            <div className="input-group mg-none">
                <label htmlFor="mail">Password</label>
                <input id="password" name="password" type="password" placeholder="Senha" value={userData.password} onChange={onChange} autoComplete='new-password' />
            </div>
        </form>
    );
}

export default FormUser;