const schema = {
    'Código': {
      prop: 'code',
      type: Number,
      required: true
    },
    'Medidor': {
      prop: 'meter',
      type: String,
      required: true
    },
    'Agente': {
      prop: 'unit',
      type: String,
      required: true
    },
    'Data': {
      prop: 'date',
      type: Date
    },
    'Valor': {
      prop: 'value',
      type: String
    },
};

export default schema;