import axios from 'axios';
import env from '~/utils/env';

const api = axios.create({
    baseURL: env.host
});

api.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
};

export default api;