import React, {useState, useEffect, useContext, ChangeEvent} from 'react';
import { GeneralContext, ModalContext } from '~/contexts';
import './styles.css';

import api from '~/services/api';
import env from '~/utils/env';

// Interface
const FormGroups: React.FC<any> = ({setReady, data}) => {
    const {setIsOpen, setFooterModal} = useContext(ModalContext);
    const {user} = useContext(GeneralContext);
    const [groupData, setGroupData] = useState<any>(data)

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {name, value} = event.target;
        const dataChange = {[name]: (name==='active' ? Number(value) : value)};

        setGroupData({...groupData, ...dataChange});
    }

    function saveGroup () {
        api.post(
            `${env.host}/ccee/groups`,
            groupData,
            {headers: { Authorization: user.token }}
        ).then((resp: any) => {
            setReady(false)
        })
    }

    useEffect(() => {
        setFooterModal(<>
            <button id="btnSave" type="button" onClick={() => { setIsOpen(false); saveGroup()}}>Salvar</button>
            <button id="btnCancel" type="button" onClick={() => setIsOpen(false)}>Cancelar</button>
        </>)
    }, [groupData])

    return (
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editUser">
            <div className="form-group">
                <div className="input-group">
                    <label htmlFor="name">Nome</label>
                    <input id="name" className={groupData.errors && groupData.errors.name ? 'error' : ''} name="name" type="text" placeholder="Nome" onChange={onChange} value={groupData.name} />
                </div>

                <div className="input-group w200">
                    <label htmlFor="active">Status</label>
                    <select name="active" id="active" className={groupData.errors && groupData.errors.active ? 'error' : ''} onChange={onChange} value={groupData.active}>
                        <option value="1">Ativo</option>
                        <option value="0">Inativo</option>
                    </select>
                </div>
            </div>
        </form>
    )
}

export default FormGroups;