import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import CurrencyInput  from 'react-currency-input-field';
import { FiEdit2, FiSave, FiTrash2, FiX } from 'react-icons/fi';
import { ModalContext, PisCofinsContext } from '~/contexts';
import Numbers from '~/utils/numbers';
import Dates from '~/utils/dates';
import sort_by from '~/utils/sort';
import './styles.css';

// Interface
const FormPisCofins: React.FC<any> = ({setProviders, id}) => {
    const {setIsOpen, setFooterModal} = useContext(ModalContext);
    const {pisCofinsData, setPisCofinsData, savePisCofins} = useContext(PisCofinsContext);
    const [clientPCofins, setClientPCofins] = useState<any>();
    const [clientPCO, setClientPCO] = useState<any>();

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {name, value} = event.target;
        let data: any = {[name]: value};

        setClientPCofins({ ...clientPCofins, [name]: (!data[name] ? '' : Number(data[name])) });
    }

    function onChangeFloat(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {name, value} = event.target;
        let data: any = {[name]: value};

        if (name.indexOf('.')>=0) {
            let nBase = name.split('.');
            data = {[nBase[0]]: { [nBase[1]]: value}};
        }

        setClientPCofins({ ...clientPCofins, [name]: (!data[name] ? '' : data[name]) });
    }

    function formatValue (value: string | number) {
        if (typeof value==='undefined') return '';
        else if (typeof value==='string') return value;

        let base = String(value).replace('.', ',').split(',');
        return base[0] + ',' + Numbers.addZero(base[1], 2);
    }

    function getMonths () {
        let ret: any = [];

        for (let i=0; i<=11; i++) {
            let item = {
                month: i+1,
                name: Dates._getNameMonth(i)
            };

            ret.push(item);
        }

        return ret;
    }

    function getYears () {
        let init = Number(Dates.getYear()),
            ret: any = [];

        for (let i=init; i>=(init-20); i--) {
            ret.push(i);
        }

        return ret;
    }

    function getMonthName(month: number) {
        month = Number(month);
        if (month===0) return 'Geral';
        return Dates._getNameMonth((month-1));
    }

    function addPisCofins() {
        let pisCofins = JSON.parse(JSON.stringify(pisCofinsData));
        let date      = new Date();

        pisCofins.push({ agent_id: id, year: date.getFullYear(), month: (date.getMonth()+1), value: 0.00 });
        pisCofins.sort(sort_by('year', { name: 'month' }));

        setPisCofinsData(pisCofins);
    }

    function editPC(elem: any) {
        let item = document.querySelector(`div[data-rel="${elem.agent_id}_${elem.year}_${elem.month}"]`);
        item?.classList.add('active');

        setClientPCofins(elem);
        setClientPCO(elem);
    }

    function deletePC(elem: any) {
        let pisCofins = JSON.parse(JSON.stringify(pisCofinsData));
        let index = pisCofins.findIndex((q: any) => q.year===elem.year && q.month===elem.month && q.price===elem.price);

        pisCofins.splice(index, 1);
        pisCofins.sort(sort_by('year', { name: 'month' }));

        setPisCofinsData(pisCofins);
    }

    function savePC(elem: any) {
        let item = document.querySelector(`div[data-rel="${elem.agent_id}_${elem.year}_${elem.month}"]`);
        item?.classList.remove('active');

        let pisCofins = JSON.parse(JSON.stringify(pisCofinsData));
        let oldData = clientPCO,
            newData = clientPCofins;

        let index = pisCofins.findIndex((q: any) => q.year===oldData.year && q.month===oldData.month);
        pisCofins.splice(index, 1, newData);
        pisCofins.sort(sort_by('year', { name: 'month' }));

        setPisCofinsData(pisCofins);
        setClientPCO(null);
        setClientPCofins(null);
    }

    function cancelPC(elem: any) {
        let item = document.querySelector(`div[data-rel="${elem.agent_id}_${elem.year}_${elem.month}"]`);
        item?.classList.remove('active');
        setClientPCofins(null);
    }

    useEffect(() => {
        setFooterModal(<>
            <button id="btnSave" type="button" onClick={() => { setIsOpen(false); savePisCofins(setProviders, id)}}>Salvar</button>
            <button id="btnCancel" type="button" onClick={() => setIsOpen(false)}>Cancelar</button>
        </>)
    }, [pisCofinsData])

    return (
        <>
            {
                pisCofinsData.length>0 ? (
                    <div className="header">
                        <div className="infos">
                            <div className="year">Ano</div>
                            <div className="month">Mês</div>
                            <div className="price">Preço</div>
                        </div>

                        <div className="buttons"></div>
                    </div>
                ) : <></>
            }

            {
                pisCofinsData.map((el: any) => (
                    <div className="line" key={`${el.agent_id}_${el.year}_${el.month}`} data-rel={`${el.agent_id}_${el.year}_${el.month}`}>
                        <div className="infos">
                            <div className="item">
                                <div className="year">{ el.year===0 ? 'Geral' : el.year }</div>
                                <div className="month">{ getMonthName(el.month) }</div>
                                <div className="price">{ Numbers.money(el.value, false) }</div>
                            </div>

                            <div className="edit">
                                <div className="year">
                                    <select
                                        name="year" id="year" onChange={onChange}
                                        defaultValue={el.year ? el.year : 0}
                                    >
                                        <option value="0">Geral</option>

                                        <optgroup label="Meses">
                                            {
                                                getYears().map((ye: any) => (
                                                    <option key={`year_${ye}`} value={ye}>{ye}</option>
                                                ))
                                            }
                                        </optgroup>
                                    </select>
                                </div>

                                <div className="month">
                                    <select
                                        name="month" id="month" onChange={onChange}
                                        defaultValue={el.month ? el.month : 0}
                                    >
                                        <option value="0">Geral</option>

                                        <optgroup label="Meses">
                                            {
                                                getMonths().map((mo: any) => (
                                                    <option key={`month_${mo.name}`} value={mo.month}>{mo.name}</option>
                                                ))
                                            }
                                        </optgroup>
                                    </select>
                                </div>

                                <div className="value">
                                    <CurrencyInput
                                        id="value" name="value" placeholder="Valor"
                                        allowDecimals={true} decimalsLimit={2}
                                        decimalSeparator="," groupSeparator="."
                                        value={formatValue(clientPCofins?.value)}
                                        onChange={onChangeFloat}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="buttons">
                            <div className="item">
                                <button className="edit" onClick={() => editPC(el)} disabled={(!clientPCofins ? false : true)}>
                                    <FiEdit2 size={16} />
                                </button>

                                <button className="del" onClick={() => deletePC(el)} disabled={(!clientPCofins ? false : true)}>
                                    <FiTrash2 size={16} />
                                </button>
                            </div>

                            <div className="edit">
                                <button className="save" onClick={() => savePC(el)}>
                                    <FiSave size={16} />
                                </button>

                                <button className="cancel" onClick={() => cancelPC(el)}>
                                    <FiX size={16} />
                                </button>
                            </div>
                        </div>
                    </div>
                ))
            }

            <button className="btn add" onClick={() => addPisCofins()}>Adicionar novo PIS/Cofins</button>
        </>
    );
}

export default FormPisCofins;