import React from 'react';
import './styles.css';

const Footer = () => {
    return (
        <footer>
            <div className="version">
                Version 1.0
            </div>

            <div className="copyright">
                Copyright © 2020 Energizou. Todos os direitos reservados.
            </div>
        </footer>
    );
}

export default Footer;