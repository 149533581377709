import React from 'react';
import { GeneralProvider } from '~/contexts';
import Routes from './routers';

const  App = () => (
    <GeneralProvider>
        <Routes />
    </GeneralProvider>
)

export default App;
