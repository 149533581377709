import React, { createContext, ReactNode, useContext, useState } from "react";
import { ProviderState } from '~/utils/interfaces';
import { GeneralContext } from "~/contexts";
import api from '~/services/api';

interface ProviderContextData {
    providerData: ProviderState;
    setProviderData: Function;
    saveProvider: Function;
}

interface ProviderProviderProps {
    children: ReactNode;
}

const initProvider = {
    ref_id     : 0,
    name       : '',
    nick       : '',
    point_hours: {
        init: '',
        end : ''
    },
    active     : 1,
    errors     : null
}

export const ProviderContext = createContext({} as ProviderContextData)


export function ProviderProvider ({ children }: ProviderProviderProps) {
    const {user} = useContext(GeneralContext);
    const [providerData, setProviderData] = useState<ProviderState>(initProvider);

    function saveProvider (updateProviders: Function) {
        api.post('/api', providerData, {headers: { Authorization: user.token }}).then((resp: any) => {
            updateProviders([])
        })
    }

    return (
        <ProviderContext.Provider value={{
            providerData,
            setProviderData,
            saveProvider
        }}>
            { children }
        </ProviderContext.Provider>
    )
}