const schema = {
  'CNPJ': {
    prop: 'cnpj',
    type: String
  },
  'Agente': {
    prop: 'client',
    type: String
  },
  'Valor': {
    prop: 'value',
    type: Number
  }
};

export default schema;