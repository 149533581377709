import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './Private';

import { Error404, Login, ForgotPassword, ResetPassword, Logout, Providers, Groups, Clients, Users } from '~/pages';

const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                {/* <PrivateRoute path="/" component={Dashboard} exact />
                <PrivateRoute path="/providers" component={Providers} exact /> */}
                <PrivateRoute path="/" component={Providers} exact />
                <PrivateRoute path="/clients" component={Clients} exact />
                <PrivateRoute path="/groups" component={Groups} exact />
                <PrivateRoute path="/users" component={Users} middleware="admin" exact />
                <PrivateRoute path="/logout" component={Logout} exact />

                <Route path="/404" component={Error404} exact />
                <Route path="/login/:text?" component={Login} exact />
                <Route path="/forgot-password" component={ForgotPassword} exact />
                <Route path="/reset-password/:token/:mail?" component={ResetPassword} exact />

                <Redirect path="*" to={{ pathname: '/404' }} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes;