import React, { Ref } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

// Props
interface Props {
    checkExistDate: boolean;
    date: any;
    show: any;
    setIsOpen?: any;
    type: String;
}

const ConfirmSubmit: React.FC<any> = (props: Props) => {
    return <>
        {
            props.checkExistDate ? (
                <div className="textDiv">
                    Já existem dados referentes a este mês. Você deseja sobrescrever os dados {props.type} referentes ao <strong>mês de {props.date.name}?</strong>
                </div>
            ) : (
                <div className="textDiv">
                    Você confirma o Upload {props.type} Referente ao <strong>mês de {props.date.name}?</strong>
                </div>
            )

        }
        {console.log(props.date)}
        <div className="buttons">
            <button className="btnConfirm" type="button" onClick={() => props.checkExistDate ? props.show(true) : props.show(false)}>Confirmar</button>
            <button className="btnCancel" type="button" onClick={() => props.setIsOpen(false)}>Cancelar</button>
        </div>
    </>
}

export default ConfirmSubmit;

