import React, { createContext, ReactNode, useState } from "react";
import Modal from '~/components/default/modal';

interface ModalContextData {
    setIsOpen: Function;
    setTitleModal: Function;
    setContentModal: Function;
    setFooterModal: Function;
}

interface ModalProviderProps {
    children: ReactNode;
}

export const ModalContext = createContext({} as ModalContextData)

export function ModalProvider ({ children }: ModalProviderProps) {
    const [isOpen, setIsOpen]             = useState<boolean>(false);
    const [titleModal, setTitleModal]     = useState<string>('');
    const [contentModal, setContentModal] = useState<any>(<></>);
    const [footerModal, setFooterModal]   = useState<any>(<></>);

    function closeModal() {
        setIsOpen(false)
    }

    return (
        <ModalContext.Provider value={{
            setIsOpen,
            setTitleModal,
            setContentModal,
            setFooterModal
        }}>
            { children }

            { isOpen ? (
                <Modal
                    status={isOpen}
                    close={closeModal}
                    title={titleModal}
                    footer={footerModal}
                >
                    { contentModal }
                </Modal>
            ) : <></> }
        </ModalContext.Provider>
    )
}