import React, { ChangeEvent, useContext, useEffect } from 'react';
import { ModalContext, ProviderContext } from '~/contexts';
import './styles.css';

// Interface
const FormProvider: React.FC<any> = ({setProviders}) => {
    const {setIsOpen, setFooterModal} = useContext(ModalContext);
    const {providerData, setProviderData, saveProvider} = useContext(ProviderContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {name, value} = event.target;
        const data = {[name]: (name==='active' ? Number(value) : value)};

        setProviderData({...providerData, ...data});
    }

    useEffect(() => {
        setFooterModal(<>
            <button id="btnSave" type="button" onClick={() => { setIsOpen(false); saveProvider(setProviders)}}>Salvar</button>
            <button id="btnCancel" type="button" onClick={() => setIsOpen(false)}>Cancelar</button>
        </>)
    }, [providerData])

    return (
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editUser">
            <div className="form-group">
                <div className="input-group">
                    <label htmlFor="name">Nome</label>
                    <input id="name" className={providerData.errors && providerData.errors.name ? 'error' : ''} name="name" type="text" placeholder="Nome" value={providerData.name} disabled />
                </div>

                <div className="input-group">
                    <label htmlFor="nick">Apelido</label>
                    <input id="nick" className={providerData.errors && providerData.errors.nick ? 'error' : ''} name="nick" type="text" placeholder="Apelido" value={providerData.nick ? providerData.nick : ''} onChange={onChange} />
                </div>
            </div>

            <div className="form-group">
                <div className="input-group">
                    <label htmlFor="point_hours_init">Início Ponta</label>
                    <input id="point_hours_init" className={providerData.errors && providerData.errors.point_hours.init ? 'error' : ''} name="point_hours.init" type="text" placeholder="Início Ponta" value={providerData.point_hours.init} disabled />
                </div>

                <div className="input-group">
                    <label htmlFor="point_hours_end">Final Ponta</label>
                    <input id="point_hours_end" className={providerData.errors && providerData.errors.point_hours.end ? 'error' : ''} name="point_hours.end" type="text" placeholder="Final Ponta" value={providerData.point_hours.end} disabled />
                </div>

                <div className="input-group w200">
                    <label htmlFor="active">Status</label>
                    <select name="active" id="active" className={providerData.errors && providerData.errors.active ? 'error' : ''} onChange={onChange} value={providerData.active}>
                        <option value="1">Ativo</option>
                        <option value="0">Inativo</option>
                    </select>
                </div>
            </div>
        </form>
    );
}

export default FormProvider;