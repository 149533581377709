import React, {Fragment, useContext, useState} from 'react';
import { FiActivity, FiDollarSign, FiDownload, FiEdit2, FiLoader } from 'react-icons/fi';
import { GeneralContext, ModalContext } from '~/contexts';
import './styles.css';

import api from '~/services/api';
import env from '~/utils/env';

// Components
import { LoadingSingle } from '~/components/default/loading';

// Interface
import { ProviderInt } from '~/utils/interfaces';

interface CompProps {
    setTooltip: Function;
    edit: Function;
    editPisCofins: Function;
    delete: Function;
    token: string;
}

type Props = ProviderInt & CompProps;

const Provider: React.FC<Props> = (props) => {
    const {setIsOpen, setTitleModal, setContentModal, setFooterModal} = useContext(ModalContext);
    const {user} = useContext(GeneralContext);
    const [tarrDown, setTarrDown] = useState(false);

    function treatPointHour (hour: string) {
        let base = hour.split(':')
        base     = base.slice(0, -1)
        let ret  = base.join(':')

        return ret;
    }

    function treatDate (dateStr: string) {
        let dBase = new Date(dateStr);
        let date  = new Intl.DateTimeFormat('pt-BR').format(dBase);
        return date;
    }

    function getTariffs (prov: any) {
        setTitleModal(`Tarifas da Distribuidora: ${prov.nick ? prov.nick : prov.name}`)
        setContentModal(<LoadingSingle />)
        setFooterModal()
        setIsOpen(true)

        api.get(`${env.host}/api/tariffs?admin=1&agent=${props.ref_id}`, {headers: { Authorization: user.token }}).then(resp => {
            let content: any = <></>;

            if (resp.data.length>0) {
                treatDate(resp.data[0].date)
                content = resp.data.map((el: any, i: number) => {
                    if (i===0) {
                        return (
                            <Fragment key={`tar_header_${i}`}>
                                <div className="header">
                                    <span className='date'>Data</span>
                                    <span className='group'>Grupo</span>
                                    <span className='modality'>Modalidade</span>
                                    <span className='type'>Tipo</span>
                                    <span className='dem_tusd'>Dem. TUSD</span>
                                    <span className='con_tusd'>Cons. TUSD</span>
                                    <span className='con_te'>Cons. TE</span>
                                </div>

                                <div className="line">
                                    <span className='date'>{treatDate(el.date)}</span>
                                    <span className='group'>{el.group}</span>
                                    <span className='modality'>{el.modality}</span>
                                    <span className='type'>{el.type}</span>
                                    <span className='dem_tusd'>{el.value_demand_tusd}</span>
                                    <span className='con_tusd'>{el.value_cons_tusd}</span>
                                    <span className='con_te'>{el.value_cons_te}</span>
                                </div>
                            </Fragment>
                        )
                    }

                    return (
                        <div key={`tar_${el.id}_${i}`} className="line">
                            <span className='date'>{treatDate(el.date)}</span>
                            <span className='group'>{el.group}</span>
                            <span className='modality'>{el.modality}</span>
                            <span className='type'>{el.type}</span>
                            <span className='dem_tusd'>{el.value_demand_tusd}</span>
                            <span className='con_tusd'>{el.value_cons_tusd}</span>
                            <span className='con_te'>{el.value_cons_te}</span>
                        </div>
                    )
                })
            } else {
                content = (
                    <div className="alert">
                        Não há tarifas cadastradas para essa distribuidora!
                    </div>
                )
            }

            setTimeout(() => setContentModal(content), 1000);
        })
    }

    function tariffsData () {
        setTarrDown(true)

        api.put(`${env.host}/save/api/tariffs?agent=${props.id}`).then(resp => {
            setTarrDown(false)
        })
    }

    return (
        <div className={`providerBox${!props.active ? ' disabled' : '' }`}>
            <div className="providerData">
                <div className="infos">
                    <h3>{ props.name } { props.nick ? `(${props.nick})` : '' }</h3>
                    <small className="hours">
                      { props.point_hours &&
                        <> <b>Hora Ponta:</b> das { treatPointHour(props.point_hours?.init) } às { treatPointHour(props.point_hours?.end) } </>
                      }
                    </small>
                </div>
            </div>

            <div className="buttons">
                <button
                    type="button"
                    className={`download ${tarrDown ? 'load' : ''}`}
                    onClick={() => tariffsData()}
                    onMouseOver={() => props.setTooltip('Atualizar as Tarifas')}
                    disabled={tarrDown}
                    data-for="tooltip_provider"
                    data-tip
                >
                    { !tarrDown ? <FiDownload size={20} /> : <FiLoader size={20} color="#FFF" strokeWidth="3" /> }
                </button>

                <button
                    type="button"
                    className="tariffs"
                    onClick={() => getTariffs(props)}
                    onMouseOver={() => props.setTooltip('Visualizar as Tarifas')}
                    data-for="tooltip_provider"
                    data-tip
                >
                    <FiDollarSign size={20} />
                </button>

                <button
                    type="button"
                    className="pisCofins"
                    onClick={() => props.editPisCofins ? props.editPisCofins() : ''}
                    onMouseOver={() => props.setTooltip('PIS/COFINS')}
                    data-for="tooltip_provider"
                    data-tip
                >
                    <FiActivity size={20} />
                </button>

                <button
                    type="button"
                    className="editUser"
                    onClick={() => props.edit ? props.edit() : ''}
                >
                    <FiEdit2 size={20} /><span> Editar</span>
                </button>
            </div>
        </div>
    );
}

export default Provider;