import React, {useState} from 'react';
import { FiCheck, FiDatabase, FiEdit2, FiTrash2, FiX } from 'react-icons/fi';
import './styles.css';

// Interface
import { GroupInt } from '~/utils/interfaces';

interface CompProps {
    edit: Function;
    delete: Function;
}

type Props = GroupInt & CompProps;

const GroupBox: React.FC<Props> = (props) => {
    const [deleteStatus, setDeleteStatus] = useState(false);
    return (
        <div className={`groupBox${!props.active ? ' disabled' : '' }`}>
            {
                !deleteStatus ? <>
                    <div className="groupData">
                        <div className="infos">
                            <span className={`status ${props.active ? 'active' : 'inactive'}`}></span>
                            <h3>{ props.name }</h3>
                            <small className="units">{props.units.length}</small>
                        </div>
                    </div>

                    <div className="buttons">
                        <button
                            type="button"
                            className="unitsGroup"
                            onClick={() => props.edit('editUnitsGroup', {id: props.id, units: props.units})}
                        >
                            <FiDatabase size={16} /><span> Unidades</span>
                        </button>

                        <button
                            type="button"
                            className="editGroup"
                            onClick={() => props.edit('createEditGroup', props, 'edit')}
                        >
                            <FiEdit2 size={16} />
                        </button>

                        <button
                            type="button"
                            className="deleteGroup"
                            onClick={() => setDeleteStatus(true)}
                        >
                            <FiTrash2 size={16} />
                        </button>
                    </div>
                </> : <>
                    <div className="alertData">
                        <p>Deseja realmente excluir o grupo <i>"{props.name}"</i>?</p>
                        <small>* Esse procedimento é irreversível!</small>
                    </div>

                    <div className="buttons">
                        <button
                            type="button"
                            className="confirm"
                            onClick={() => props.delete(props.id)}
                        >
                            <FiCheck size={16} />
                        </button>

                        <button
                            type="button"
                            className="cancel"
                            onClick={() => setDeleteStatus(false)}
                        >
                            <FiX size={16} />
                        </button>
                    </div>
                </>
            }
        </div>
    );
}

export default GroupBox;