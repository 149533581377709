import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaSignOutAlt, FaChargingStation, FaIndustry, FaUsers, FaLayerGroup } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import './styles.css';

import logo from '~/assets/images/logo_minimal.svg';

const Menu = () => {
    const {user} = useContext(GeneralContext)
    let location = useLocation();
    let pathName = location.pathname;

    return (
        <nav className="menu">
            <div className="links">
                <Link to='/' className="logo">
                    <img src={logo} alt="Energizou"/>
                </Link>

                <Link to='/' className={pathName==='/' ? 'active' : ''}>
                    <FaChargingStation size={22} />
                    <span>Distribuidoras</span>
                </Link>

                <Link to='/clients' className={pathName.indexOf('/clients')>=0 ? 'active' : ''}>
                    <FaIndustry size={22} />
                    <span>Clientes</span>
                </Link>

                <Link to='/groups' className={pathName.indexOf('/groups')>=0 ? 'active' : ''}>
                    <FaLayerGroup size={22} />
                    <span>Grupos</span>
                </Link>

                {
                    user.role==='admin' && (
                        <Link to='/users' className={pathName.indexOf('/users')>=0 ? 'active' : ''}>
                            <FaUsers size={22} />
                            <span>Usuários</span>
                        </Link>
                    )
                }
            </div>

            <Link to='/logout' className="logout">
                <FaSignOutAlt size={22} />
                <span>Logout</span>
            </Link>
        </nav>
    );
}

export default Menu;