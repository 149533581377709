import React, { createContext, ReactNode, useEffect, useState } from "react";
import { ModalProvider, FormsProvider } from '~/contexts';

// Login
import cryptos from '~/utils/cryptos';
import storage from '~/utils/storage';

interface UserInterface {
    id: number;
    role: string,
    name: string;
    image: string;
    image_url: string;
    mail: string;
    active: boolean;
    token: string;
    refreshToken: string;
}

interface GeneralContextData {
    logged: boolean;
    user: UserInterface;
    login: Function;
    logout: Function;
}

interface GeneralProviderProps {
    children: ReactNode;
}

// User Init
const userInit: UserInterface = {
    id: 0,
    role: 'guest',
    name: 'Guest',
    image: '',
    image_url: '',
    mail: '',
    active: true,
    token: '',
    refreshToken: ''
}

export const GeneralContext = createContext({} as GeneralContextData)

export function GeneralProvider ({ children }: GeneralProviderProps) {
    const [ready, setReady]   = useState<boolean>(false);
    const [logged, setLogged] = useState<boolean>(false);
    const [user, setUser]     = useState<UserInterface>(userInit);

    function login (userData: any) {
        let data = cryptos.encryptWeb(userData)
        storage.set('UToken', data)
        setUser(userData)
        setLogged(true)
    }

    function logout () {
        storage.remove('UToken')
        setUser(userInit)
        setLogged(false)
    }

    useEffect(() => {
        if(!ready) {
            let data = storage.get('UToken')

            if (data) {
                let userData = cryptos.decryptWeb(data)
                setUser(userData)
                setLogged(true)
                setReady(true)
            } else {
                setUser(userInit)
                setLogged(false)
                setReady(true)
            }
        }
    }, [ready, logged, user]);

    return (
        <GeneralContext.Provider value={{ logged, user, login, logout }}>
            <FormsProvider>
                <ModalProvider>
                        { ready ? children : <></> }
                </ModalProvider>
            </FormsProvider>
        </GeneralContext.Provider>
    )
}