import dateFormat  from 'dateformat';

const dates = {
    getNow: function (format?: string) {
        let date  = new Date();
        return dateFormat(date, format ? format : "dd/mm/yyyy");
    },

    getDate: function (date: string) {
        date = date + ' 03:00:00';

        let year  = Number(dateFormat(date, 'yyyy')),
            month = Number(dateFormat(date, 'mm')),
            day   = Number(dateFormat(date, 'dd'));

        return {year, month, day};
    },

    getDateFormatted: function (date: string, format?: string) {
        return dateFormat(date, format ? format : 'dd-mm-yyyy');
    },

    getDay: function () {
        let date = new Date(),
            day = date.getDate()-1;
        return (day<10 ? '0' + day : day);
    },

    getMonth: function () {
        let date = new Date();
        return this._getNameMonth(date.getMonth());
    },

    getYear: function () {
        let date = new Date();
        return date.getUTCFullYear();
    },

    getMonthYear: function () {
        let date  = new Date(),
            month = this._getNameMonth(date.getMonth()),
            year  = date.getUTCFullYear();

        return month + '/' + year;
    },

    getMonthsYear: function (old: boolean = false) {
        let date  = new Date(),
            month = date.getMonth(),
            year  = date.getUTCFullYear(),
            ret   = [];

        if (month>=1) {
            for (let i=month; i>=0; i--) {
                ret.push({year, month: i+1, name: this._getNameMonth(i)});
            }

            if (month<12) {
                let qtty      = 12-(old ? ret.length-1 : ret.length);
                let monthInit = 12;

                for (let f=qtty; f>0; f--) {
                    ret.push({year: (year-1), month: monthInit, name: this._getNameMonth(monthInit-1)});
                    monthInit--;
                }
            }
        } else {
            if (!old) {
                for (let y=month+1; y>=0; y--) {
                    ret.push({year: year, month: y+1, name: this._getNameMonth(y)});
                }
            }

            for (let z=11; z>=0; z--) {
                ret.push({year: (year-1), month: z+1, name: this._getNameMonth(z)});
            }
        }

        return ret;
    },

    getMonthsTwoYear: function (old: boolean = false) {
        let date = new Date(),
        month = date.getMonth(),
        year = date.getUTCFullYear(),
        ret = [];

        for (let i = month; i >= 0; i--) {
            ret.push({ year, month: i + 1, name: this._getNameMonth(i) });
        }

        let monthInit = 11;
        for (let i = 0; i < 12; i++) {
            ret.push({
                year: year - 1,
                month: monthInit + 1,
                name: this._getNameMonth(monthInit),
            });
            monthInit--;
        }

        if (old || ret.length < 24) {
            monthInit = 11;
            for (let i = ret.length; i < 24; i++) {
                ret.push({
                    year: year - 2,
                    month: monthInit + 1,
                    name: this._getNameMonth(monthInit),
                });
                monthInit--;
            }
        }

        return ret;
    },

    getCurrMonthAPI: function () {
        let date     = new Date(),
            ret: any = { year: date.getUTCFullYear(), month: date.getMonth()};

        if (ret.month===0) {
            ret.year  = ret.year-1;
            ret.month = 12;
        }

        return ret;
    },

    _getNameMonth: function (month: number) {
        let monthArr = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ];


        return monthArr[month];
    }
}

export default dates;