import React, { useState, ChangeEvent } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import { FaTimes, FaEnvelopeOpen, FaArrowLeft } from 'react-icons/fa';

// Elements
import './styles.css';
import logo from '~/assets/images/logo.svg';

const ForgotPassword = () => {
    const history               = useHistory();
    const [ready, setReady]     = useState(true);
    const [mail, setMail]       = useState('');
    const [errors, setErrors]   = useState<string[]>([]);

    // Add Login class
    document.querySelector('#root')?.classList.add('login');


    function onChange(event: ChangeEvent<HTMLInputElement>) {
        const {value} = event.target;
        setMail(value)
    }

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setErrors([]);
        setReady(false);

        if (mail==='') {
            var errArr = [];
            errArr.push('O campo de email está vazio');
            setErrors(errArr);
            setReady(true);
            return;
        }
    }

    function treatErrors() {
        if (Array.isArray(errors) && errors.length>0) {
            return (
                <div className="boxErrors">
                    <div className="errors">
                        { errors.map(el => <span>{ el }</span>) }
                    </div>

                    <button type="button" onClick={() => { setErrors([]) }} className="close">
                        <FaTimes size="16" />
                    </button>
                </div>
            )
        }
    }

    return (
        <>
            <Helmet title="Esqueci minha senha - Clientes Energizou" />

            <main className="login-page forgot">
                <Link to="/" className="logo">
                    <img src={logo} alt="Energizou"/>
                </Link>

                { treatErrors() }

                <div className="body-box">
                    <form method="post" onSubmit={onSubmit} autoComplete="off">
                        <h3>
                            <Link to="/login" className="logo" title="Voltar ao Login">
                                <FaArrowLeft />
                            </Link>

                            Esqueceu sua senha?
                        </h3>

                        <div className="input-group has-feedback">
                            <input id="mail" name="mail" type="mail" placeholder="Email" value={mail} onChange={onChange} disabled={ready ? false : true} />
                            <FaEnvelopeOpen className="icons form-control-feedback" size="18"></FaEnvelopeOpen>
                        </div>

                        <div className="input-group">
                            <button id="btnLogin" type="submit" disabled={ready ? false : true}>Recuperar Senha</button>
                        </div>
                    </form>
                </div>

                <div className="copyright">
                    Copyright © 2020 - Todos os direitos reservados
                </div>
            </main>
        </>
    );
}

export default ForgotPassword;