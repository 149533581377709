const schema = {
  'Agente': {
    prop: 'client',
    type: String,
    required: true
  },
  'CNPJ': {
    prop: 'cnpj',
    type: String,
    required: true
  },
  'Valor': {
    prop: 'value',
    type: Number,
    required: true
  }
};

export default schema;