import React, { createContext, ReactNode, useContext, useState } from "react";
import { UserState } from '~/utils/interfaces';
import { GeneralContext } from "~/contexts";
import api from '~/services/api';

interface UserContextData {
    userData: UserState;
    setUserData: Function;
    saveUser: Function;
    deleteUser: Function;
}

interface UserProviderProps {
    children: ReactNode;
}

const initUser = {
    type: '',
    image: '',
    name: '',
    mail: '',
    password: '',
    active: 1,
    errors: null
}

export const UserContext = createContext({} as UserContextData)


export function UserProvider ({ children }: UserProviderProps) {
    const {user}                  = useContext(GeneralContext);
    const [userData, setUserData] = useState<UserState>(initUser);

    function saveUser (updateUsers: Function) {
        api.post('/users', userData, {headers: { Authorization: user.token }}).then((resp: any) => {
            updateUsers([])
        })
    }

    function deleteUser (id: number, updateUsers: Function) {
        api.delete(`/users/${id}`, {headers: { Authorization: user.token }}).then(() => {
            updateUsers([])
        })
    }

    return (
        <UserContext.Provider value={{
            userData,
            setUserData,
            saveUser,
            deleteUser
        }}>
            { children }
        </UserContext.Provider>
    )
}