import React, { useState, ChangeEvent, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import { FaTimes, FaEnvelopeOpen, FaLock } from 'react-icons/fa';

// Elements
import './styles.css';
import logo from '~/assets/images/logo.svg';

interface Values {
    token: string;
    mail: string;
    password: string;
    password_confirm?: string;
}

const initValues = { token: '', mail: '', password: '', password_confirm: ''};

const ResetPassword: React.FC<any> = ({ match }) => {
    const { token, mail }     = match.params;
    const history             = useHistory();
    const [ready, setReady]   = useState(false);
    const [values, setValues] = useState<Values>(initValues);
    const [errors, setErrors] = useState<string[]>([]);

    // Add Login class
    document.querySelector('#root')?.classList.add('login');

    useEffect(() => {
        let newValues: any          = {};
        if  (token) newValues.token = token;
        if  (mail) newValues.mail   = mail;
        setValues({...values, ...newValues})
        setReady(true)
    }, [])

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        const {name, value} = event.target;

        setValues({
            ...values,
            [name]: value
        })
    }

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let errArr = [];

        if (values.mail==='' || values.password==='' || values.password_confirm==='') {
            if (values.mail==='') errArr.push('O campo de email está vazio');
            if (values.password==='') errArr.push('O campo de senha está vazio');
            if (values.password_confirm==='') errArr.push('O campo de confirmação da senha está vazio');

            setErrors(errArr);
            return;
        }

        if (values.password!=='' && values.password_confirm!=='' && values.password!==values.password_confirm) {
            errArr.push('A senha e a confirmação não são iguais');

            setErrors(errArr);
            return;
        }
    }

    function treatErrors() {
        if (Array.isArray(errors) && errors.length>0) {
            return (
                <div className="boxErrors">
                    <div className="errors">
                        { errors.map(el => <span>{ el }</span>) }
                    </div>

                    <button type="button" onClick={() => { setErrors([]) }} className="close">
                        <FaTimes size="16" />
                    </button>
                </div>
            )
        }
    }

    return (
        <>
            <Helmet title="Esqueci minha senha - Clientes Energizou" />

            <main className="login-page forgot">
                <Link to="/" className="logo">
                    <img src={logo} alt="Energizou"/>
                </Link>

                { treatErrors() }

                <div className="body-box">
                    <form method="post" onSubmit={onSubmit} autoComplete="off">
                        <h3>Defina sua nova senha</h3>

                        <div className="input-group has-feedback">
                            <input id="mail" name="mail" type="mail" placeholder="Email" value={values.mail} onChange={onChange} disabled={!ready} />
                            <FaEnvelopeOpen className="icons form-control-feedback" size="18" />
                        </div>

                        <div className="input-group has-feedback">
                            <input id="password" name="password" type="password" placeholder="Senha" value={values.password} onChange={onChange} disabled={!ready} />
                            <FaLock className="icons form-control-feedback" size="18" />
                        </div>

                        <div className="input-group has-feedback">
                            <input id="password_confirm" name="password_confirm" type="password" placeholder="Confirmação da Senha" value={values.password_confirm} onChange={onChange} disabled={!ready} />
                            <FaLock className="icons form-control-feedback" size="18" />
                        </div>

                        <div className="input-group">
                            <button id="btnLogin" type="submit" disabled={!ready}>Recuperar Senha</button>
                        </div>
                    </form>
                </div>

                <div className="copyright">
                    Copyright © 2020 - Todos os direitos reservados
                </div>
            </main>
        </>
    );
}

export default ResetPassword;