import React, {useState, useEffect, useContext, ChangeEvent} from 'react';
import { GeneralContext, ModalContext } from '~/contexts';
import sort_by from '~/utils/sort';
import './styles.css';

import api from '~/services/api';
import env from '~/utils/env';
import { FiEdit2, FiSave, FiTrash2, FiX } from 'react-icons/fi';

// Interface
const FormUnitsGroup: React.FC<any> = ({setGroupReady, group}: any) => {
    const {setIsOpen, setFooterModal} = useContext(ModalContext);
    const {user}                      = useContext(GeneralContext);

    // States
    const [unitsData, setUnitsData] = useState<any[]>([]);
    const [unitsList, setUnitsList] = useState<any[]>([]);
    const [ready, setReady]         = useState(false);
    const [unitsP, setUnitsP]       = useState<any>();
    const [unitsPCO, setUnitsPCO]   = useState<any>();

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {value} = event.target;
        let unitSel = unitsList.find(el => Number(el.id)===Number(value))

        setUnitsP({ ...unitsP, ...unitSel })
    }

    function addUnit() {
        let units = JSON.parse(JSON.stringify(unitsData));

        if (unitsData.length===0 || !unitsData.findIndex(el => el.name!=='')) {
            units.push({ id: 0, name: '' });
            units.sort(sort_by('name'));

            setUnitsData(units);
        }
    }

    function editUnits(elem: any) {
        let item = document.querySelector(`div[data-rel="${elem.id}_${elem.name}`);
        item?.classList.add('active');

        setUnitsP(elem);
        setUnitsPCO(elem);
    }

    function deleteUnits(elem: any) {
        let units = JSON.parse(JSON.stringify(unitsData));
        let index = units.findIndex((q: any) => q.id===elem.id && q.name===elem.name);

        units.splice(index, 1);
        units.sort(sort_by('name'));

        setUnitsData(units);
    }

    function saveUnits(elem: any) {
        let item = document.querySelector(`div[data-rel="${elem.id}_${elem.name}`);
        item?.classList.remove('active');

        let units = JSON.parse(JSON.stringify(unitsData));
        let oldData = unitsPCO,
        newData = unitsP;

        let exists = units.find((el:any) => el.id === newData.id)
        if(exists !== undefined){
            alert("Essa unidade já foi selecionada")
            setUnitsPCO(null);
            setUnitsP(null);
            return
        } 

        let index = units.findIndex((q: any) => q.id===oldData.id && q.name===oldData.name);
        units.splice(index, 1, newData);
        units.sort(sort_by('name'));

        setUnitsData(units);
        setUnitsPCO(null);
        setUnitsP(null);
    }

    function cancelUnits(elem: any) {
        let item = document.querySelector(`div[data-rel="${elem.id}_${elem.name}"]`);
        item?.classList.remove('active');
        setUnitsP(null);
    }

    function getUnits() {
        api.get(
            `${env.host}/ccee`,
            {headers: { Authorization: user.token }}
        ).then(resp => {
            let units = resp.data;
            units.sort(sort_by('name'));
            setUnitsList(units)
            setReady(true)
        })
    }

    async function saveUnitsDB() {
        let units: any = [];
        if (unitsData.length>0) {
            await unitsData.reduce(async (memo: any, el: any) => {
                await memo;
                units.push({
                    group_id: group.id,
                    unit_id: el.id
                })
            }, Promise.resolve())
        }

        const data = unitsData.length>0 ? units : {id: group.id};

        api.post(
             `${env.host}/ccee/groups/unit`,
            data,
            {headers: { Authorization: user.token }}
        ).then((resp: any) => {
            setGroupReady(false)
        })
    }

    useEffect(() => {
        if (!ready) {
            setFooterModal(<></>)
            setUnitsData(group.units)
            getUnits()
        } else {
            setFooterModal(<>
                <button id="btnSave" type="button" onClick={() => { setIsOpen(false); saveUnitsDB()}}>Salvar</button>
                <button id="btnCancel" type="button" onClick={() => setIsOpen(false)}>Cancelar</button>
            </>)
        }
    }, [ready, unitsData])

    return <>
        {
            unitsData.length>0 && (
                <div className="header">
                    <div className="unit">Unidade</div>
                    <div className="buttons"></div>
                </div>
            )
        }

        {
            unitsData.length>0 ? (
                unitsData.map(
                    (el: any) => <div className="line" key={`${el.id}_${el.name}`} data-rel={`${el.id}_${el.name}`}>
                        <div className="infos">
                            <div className="item">
                                {el.name!=='' ? el.name : 'Nova Unidade'}
                            </div>

                            <div className="edit">
                                <select
                                    name="unit" id="unit" onChange={onChange}
                                    defaultValue={el.id ? el.id : 0}
                                >
                                    <optgroup label="Meses">
                                        <option value="0">
                                            Selecione a unidade
                                        </option>
                                        {
                                            unitsList.map((unitSel: any) => (
                                                <option key={`unitSel_${unitSel.id}`} value={unitSel.id}>
                                                    {unitSel.name}
                                                </option>
                                            ))
                                        }
                                    </optgroup>
                                </select>
                            </div>
                        </div>

                        <div className="buttons">
                            <div className="item">
                                <button className="edit" onClick={() => editUnits(el)} disabled={(!unitsP ? false : true)}>
                                    <FiEdit2 size={16} />
                                </button>

                                <button className="del" onClick={() => deleteUnits(el)} disabled={(!unitsP ? false : true)}>
                                    <FiTrash2 size={16} />
                                </button>
                            </div>

                            <div className="edit">
                                <button className="save" onClick={() => saveUnits(el)}>
                                    <FiSave size={16} />
                                </button>

                                <button className="cancel" onClick={() => cancelUnits(el)}>
                                    <FiX size={16} />
                                </button>
                            </div>
                        </div>
                    </div>
                )
            ) : <div className='alertUnits'>Sem Unidades</div>
        }

        <button className="btn add" onClick={() => addUnit()}>Adicionar Unidade</button>
    </>
}

export default FormUnitsGroup;