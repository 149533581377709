import React, { createContext, ReactNode, useContext, useState } from "react";
import { PisCofinsState } from '~/utils/interfaces';
import { GeneralContext } from "~/contexts";
import api from '~/services/api';

interface PisCofinsContextData {
    pisCofinsData: ClientPisCofinsModalState;
    setPisCofinsData: Function;
    savePisCofins: Function;
}

interface PisCofinsProviderProps {
    children: ReactNode;
}

interface ClientPisCofinsModalState extends Array<PisCofinsState>{};

export const PisCofinsContext = createContext({} as PisCofinsContextData)

export function PisCofinsProvider ({ children }: PisCofinsProviderProps) {
    const {user} = useContext(GeneralContext);
    const [pisCofinsData, setPisCofinsData] = useState<ClientPisCofinsModalState>([]);

    function savePisCofins (updatePisCofins: Function, id: any) {
        const data = pisCofinsData.length>0 ? pisCofinsData : { id };

        api.post('/api/pis_cofins', data, {headers: { Authorization: user.token }}).then((resp: any) => {
            updatePisCofins([])
        })
    }

    return (
        <PisCofinsContext.Provider value={{
            pisCofinsData,
            setPisCofinsData,
            savePisCofins
        }}>
            { children }
        </PisCofinsContext.Provider>
    )
}