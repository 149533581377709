import React, { ReactNode } from "react";
import { UserProvider, ProviderProvider, PisCofinsProvider } from '~/contexts';

interface FormsProviderProps {
    children: ReactNode;
}

export function FormsProvider ({ children }: FormsProviderProps) {
    return (
        <UserProvider>
            <ProviderProvider>
                <PisCofinsProvider>
                    { children }
                </PisCofinsProvider>
            </ProviderProvider>
        </UserProvider>
    )
}