import React from 'react';
import './styles.css';
import { FaUser } from 'react-icons/fa';

import UserBox from '../user';

interface Props {
    title: any;
    subtitle: any;
}

const Header: React.FC<Props> = (props) => {
    return (
        <header>
            <h1>
                { props.title }
                { props.subtitle ? <small>&nbsp;&nbsp;-&nbsp;&nbsp;{ props.subtitle }</small> : <></> }
            </h1>

            <div className="infos">
                <div className="user">
                    <FaUser size={20} />
                </div>

                {/* <UserBox /> */}
            </div>
        </header>
    );
}

export default Header;