import React, { useContext } from 'react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { GeneralContext } from '~/contexts';

import { UserInt } from '~/utils/interfaces';
import user_image from '~/assets/images/user.jpg';
import './styles.css';

interface CompProps {
    edit: Function;
    delete: Function;
    token: string;
}

type Props = UserInt & CompProps;

const Users: React.FC<Props> = (props) => {
    const {user} = useContext(GeneralContext);

    function getUserType (type: string) {
        switch (type) {
            case 'admin':
                return 'Administrador';

            case 'client':
                return 'Cliente';

            default:
                return 'Usuário';
        }
    }

    return (
        <div className="userBox">
            <div className="userData">
                <img src={props.image_url ? props.image_url : user_image} alt={`Imagem do usuário ${props.name}`} />

                <div className="infos">
                    <h3>{ props.name }</h3>
                    <div className="type">{ getUserType(props.type) }</div>
                </div>
            </div>

            <div className="buttons">
                <button
                    type="button"
                    className="editUser"
                    onClick={() => props.edit ? props.edit() : ''}
                >
                    <FiEdit2 size={20} /><span> Editar</span>
                </button>

                <button
                    type="button"
                    className="deleteUser"
                    onClick={() => props.delete ? props.delete() : ''}
                    disabled={user.id===props.id}
                >
                    <FiTrash2 size={20} /><span> Excluir</span>
                </button>
            </div>
        </div>
    );
}

export default Users;