import React, { useState, useEffect, useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import { FiPlus } from 'react-icons/fi';
import { GeneralContext, ModalContext, UserContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';

// Import Components
import AdminDesign from '~/components/admin';
import UsersBox from '~/components/parts/UsersBox';
import {LoadingSingle} from '~/components/default/loading';

// Form
import { FormUser } from '~/forms';

const Users = () => {
    const {setIsOpen, setTitleModal, setContentModal, setFooterModal} = useContext(ModalContext);
    const {setUserData, deleteUser} = useContext(UserContext);
    const {user}                    = useContext(GeneralContext);
    const [ready, setReady]         = useState(false);
    const [users, setUsers]         = useState([]);
    const [tooltip, setTooltip]     = useState('');

    function openModal(modal: any, data?: any) {
        let dataUser = {
            id      : 0,
            type    : 'user',
            image   : '',
            name    : '',
            mail    : '',
            password: '',
            active  : 1,
            errors  : null
        };

        switch (modal) {
            case 'addUser':
                setTitleModal('Adicionando Usuário');
                break;

            case 'editUser':
                setTitleModal('Editando Usuário');

                if (data) {
                    dataUser.id     = data.id;
                    dataUser.type   = data.type;
                    dataUser.image  = data.image_url;
                    dataUser.name   = data.name;
                    dataUser.mail   = data.mail;
                    dataUser.active = data.active;
                }
                break;

            default:
                setTitleModal('');
                setContentModal(<></>);
                setFooterModal(<></>);
                break;
        }

        setUserData(dataUser);
        setContentModal(<FormUser setUsers={setUsers} />);
        setIsOpen(true)
    }

    function delUser (usr: any) {
        setTitleModal('Excluindo Usuário')
        setContentModal(<>
            <div>Deseja realmente excluir o usuário <i>"{ usr.name }"?</i></div>
            <div className="alert-text">* Tenha cuidado, esse processo é irreversível!</div>
        </>)
        setFooterModal(<>
            <button id="btnDelete" type="button" onClick={() => { setIsOpen(false); deleteUser(usr.id, setUsers)}}>Excluir</button>
            <button id="btnCancel" type="button" onClick={() => setIsOpen(false)}>Cancelar</button>
        </>)
        setIsOpen(true)
    }

    useEffect(() => {
        if (users.length===0) {
            setReady(false)
            api.get('/users', {headers: { Authorization: user.token }}).then(resp => {
                setUsers(resp.data)
                setReady(true)
            })
        }
    }, [users]);

    return (
        <>
            <AdminDesign pageTitle="Gerenciamento de Usuários - API Energizou" title="Usuários" subtitle="Gestão de Usuários" className="users">
            {
                ready ? (
                    <>
                        <div className="buttons">
                            <button
                                type="button"
                                className="addUser"
                                onClick={() => openModal('addUser')}
                                onMouseOver={() => setTooltip('Adicionar Usuário')}
                                data-for="tooltip_users"
                                data-tip
                            >
                                <FiPlus size="16" />
                            </button>
                        </div>

                        <div className={`users ${!ready ? 'loading' : ''}`}>
                            {
                                users.map((el: any) => <UsersBox
                                    key={`user_${el.id}`}
                                    edit={() => openModal('editUser', el)}
                                    delete={() => delUser(el)}
                                    {...el}
                                />)
                            }
                        </div>

                        <ReactTooltip
                            id="tooltip_users"
                            place="bottom"
                            effect="solid"
                            type="info"
                        >
                            { tooltip }
                        </ReactTooltip>
                    </>
                ) : <LoadingSingle />
            }
            </AdminDesign>
        </>
    );
}

export default Users;