import React, { useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { RiLoader2Line } from 'react-icons/ri';
import Button from '~/components/forms/Button';
import api from '~/services/api';

const FeesPaymentBox: React.FC<any> = (props) => {
    const [downReady, setDownReady] = useState<Boolean>(true);
    const infos = props.infos;

    function download (path: string) {
        setDownReady(false);
        let fileBase = path.split('/');
        let fileName = fileBase[1];

        api.get(`files/${path}`, {
            responseType: 'blob'
        }).then(resp => {
            let url      = window.URL.createObjectURL(new Blob([resp.data]));
            let link     = document.createElement('a');

            setTimeout(() => {
                link.href = url;
                link.setAttribute("id", "download");
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
                setDownReady(true);
            }, 1000);
        })
    }

    return <div className="infoLine">
        <div className="client">{infos.unit_name}</div>
        <div className="cnpj">{infos.unit_cnpj}</div>
        <div className="date">{(infos.month<=9 ? '0' : '') + infos.month}/{infos.year}</div>
        <div className="file">
            <Button
                type="button"
                className={`down${!downReady ? ' load' : ''}`}
                icon={!downReady ? <RiLoader2Line size={12} /> : <FaDownload />}
                text="Download"
                func={() => download(infos.path)}
                disabled={!downReady ? true : false}
            />
        </div>
    </div>;
}

export default FeesPaymentBox;