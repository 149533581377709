import React, { useContext, useEffect, useRef, useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import api from '~/services/api';
import dates from '~/utils/dates';
import FeesPaymentBox from '~/components/parts/FeesPaymentBox';
import './styles.css';
import sort_by from '~/utils/sort';
import { ModalContext } from '~/contexts';
import ConfirmSubmit from '~/components/forms/ConfirmSubmit';

// Interface
const FormCCEETaxs: React.FC<any> = () => {
    const [datesArr, setDates]                = useState<any>(null);
    const [file, setFile]                     = useState<any>(null);
    const [date, setDate]                     = useState<any>(null);
    const [infos, setInfos]                   = useState<any>(null);
    const fileRef                             = useRef<HTMLInputElement>(null);

    const [check, setCheck]                   = useState<boolean>(false);
    const [checkExistDate, setcheckExistDate] = useState<boolean>(false);
    const { setIsOpen }                       = useContext(ModalContext);

    async function show(datacheck: any) {
        setInfos(null);
        setCheck(false);

        if (file) {
            const data = new FormData();
            data.append('year', date.year);
            data.append('month', date.month);
            data.append('file', file);

            await api.post(`save/uploads/fees_payment/${datacheck}`, data, {
                headers: { 'Content-Type': 'multipart/form-data;' }
            }).then(resp => {
                setInfos(resp.data.sort(sort_by({ name: "unit_name" })));
                setFile(null);

                setIsOpen(true)
                setCheck(false)
                setcheckExistDate(false)
            }).catch(error => {

                setCheck(true)
                setcheckExistDate(true)
            });
        }
    }

    useEffect(() => {
        let datesBase = dates.getMonthsYear();
        setDates(datesBase);
        setDate(datesBase[0]);
    }, [])

    return (
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="uploadProinfa">
            {
                check &&
                <ConfirmSubmit checkExistDate={checkExistDate} date={date} show={show} setIsOpen={setIsOpen} type={"dos Boleto Taxa CCEE"}/>
            }
            {!check &&
                <>
                    <div className="fileBox">
                        <input id="inputFile" name="inputFile" type="file" onChange={ev => setFile(ev.target.files ? ev.target.files[0] : null)} ref={fileRef} />

                        <button className="selectFile" onClick={() => { fileRef.current?.click(); setInfos(null) }}>
                            {file ? file.name : 'Clique aqui para selecionar o arquivo!'}
                        </button>

                        <select name="date" id="date" onChange={ev => setDate(JSON.parse(ev.currentTarget.value))}>
                            {
                                datesArr && datesArr.map((el: any) => (
                                    <option key={`months_${el.year}-${el.month}`} value={JSON.stringify(el)}>{el.name} de {el.year}</option>
                                ))
                            }
                        </select>

                        <button className='saveFile' onClick={() => setCheck(true)} disabled={!file}>
                            <FiUpload size={20} />
                        </button>
                    </div>

                    <hr />

                    <div className="content cceeFees">
                        {
                            infos ? <div className='infoBox'>
                                <div className="infoHeader">
                                    <div className="client">Name</div>
                                    <div className="cnpj">CNPJ</div>
                                    <div className="date">Mês</div>
                                    <div className="file">Boleto</div>
                                </div>

                                {
                                    infos.map((el: any) => <FeesPaymentBox key={`feesLine_${el.unit_id}`} infos={el} />)
                                }
                            </div> : <div className="alert">Faça upload do arquivo!</div>
                        }
                    </div>
                </>
                }
        </form>
    );
}

export default FormCCEETaxs;