import React, { useContext, useEffect, useRef, useState } from 'react';
import { FiMinus, FiPlus, FiUpload } from 'react-icons/fi';
import api from '~/services/api';
import numbers from '~/utils/numbers';
import readXlsx from '~/utils/readXlsx';
import schema from './schema';
import './styles.css';
import { ModalContext } from '~/contexts';
import ConfirmSubmit from '~/components/forms/ConfirmSubmit';

// Interface
const FormProinfa: React.FC<any> = () => {
    const [file, setFile]                     = useState<any>(null);
    const [infos, setInfos]                   = useState<any>(null);
    const [boxOpen, setBoxOpen]               = useState<number>(0);
    const fileRef                             = useRef<HTMLInputElement>(null);

    const [check, setCheck]                   = useState<boolean>(false);
    const [checkExistDate, setcheckExistDate] = useState<boolean>(false);
    const { setIsOpen }                       = useContext(ModalContext);

    async function show(datacheck: any) {
        setInfos(null);
        setCheck(false);

        if (file) {
            let data: any = await readXlsx.getXlsxProInfa(file, schema);
            try {
                await api.post(`save/uploads/proinfa/${datacheck}`, data)

                setInfos(data);
                setFile(null);

                setIsOpen(true)
                setCheck(false)
                setcheckExistDate(false)
            } catch (error) {
                setCheck(true)
                setcheckExistDate(true)
            }
        }
    }

    return (
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="uploadProinfa">
            {
                check &&
                <ConfirmSubmit checkExistDate={checkExistDate} date={{name:"acordo com o arquivo selecionado"}} show={show} setIsOpen={setIsOpen} type={"do Proinfa"} />
            }
            {
                !check &&
                <>
                    <div className="fileBox">
                        <input id="inputFile" name="inputFile" type="file" onChange={ev => setFile(ev.target.files ? ev.target.files[0] : null)} ref={fileRef} />

                        <button className="selectFile" onClick={() => { fileRef.current?.click(); setInfos(null) }}>
                            {file ? file.name : 'Clique aqui para selecionar o arquivo!'}
                        </button>

                        <button className='saveFile' onClick={() => setCheck(true)} disabled={!file}>
                            <FiUpload size={20} />
                        </button>
                    </div>

                    <hr />

                    <div className="content">
                        {
                            infos ? (
                                <div className='infoBox proinfa'>
                                    <div className="infoHeader">
                                        <div className="code">Código</div>
                                        <div className="unit">Unidade</div>
                                        <div className="datas">Infos</div>
                                        <div className="btn"></div>
                                    </div>

                                    {
                                        infos.map((el: any) => (
                                            <div className="infoColumn" key={`box_${el.code}`}>
                                                <div className="infos">
                                                    <div className="code">{el.code}</div>
                                                    <div className="unit">{el.unit}</div>
                                                    <div className="datas">{el.data.length}</div>
                                                    <div className="btn">
                                                        <button className={boxOpen === el.code ? 'minus' : ''} onClick={() => boxOpen === el.code ? setBoxOpen(0) : setBoxOpen(el.code)}>
                                                            {boxOpen === el.code ? <FiMinus size={12} /> : <FiPlus size={12} />}
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className={`data${boxOpen === el.code ? ' openned' : ''}`}>
                                                    {
                                                        el.data.map((el2: any) => (
                                                            <div className="box" key={`data_${el2.date.getMonth() + 1}_${el2.date.getFullYear()}`}>
                                                                <div className="date"><b>Data:</b> {((el2.date.getMonth() + 1) <= 9 ? '0' : '') + (el2.date.getMonth() + 1)}/{el2.date.getFullYear()}</div>
                                                                <div className="value"><b>Valor:</b> {numbers.format(el2.value, true, 3)}</div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            ) :
                                <div className="alert">Faça upload do arquivo!</div>
                        }
                    </div>
                  </>
                }
        </form>
    );
}

export default FormProinfa;