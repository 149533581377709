import readXlsxFile from 'read-excel-file';
import sort_by from './sort';

const readXlsx = {
    getXlsx: async function (file: any, date: any, schema: any, schema2?: any) {
        let data = await this._read(file, date, schema);
        if (!data && schema2) data = await this._read(file, date, schema2);
        return data;
    },

    getXlsxProInfa: async function (file: any, schema: any, schema2?: any) {
        let data = await this._readProInfa(file, schema);
        if (!data) data = await this._readProInfa(file, schema2);
        return data;
    },

    _read: async function (file: any, date: any, schema: any) {
        return await readXlsxFile(file, {schema}).then(async ({rows, errors}) => {
            if (errors.length>0) {
                console.log("Ocorreu um erro! Formato de arquivo incorreto ou sem dados encontrados...")
                // console.log(errors)
                return null;
            }

            let ret: any  = [];
            rows.map((el: any) => {
                ret.push({
                    client: el.client,
                    cnpj: el.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
                    date: new Date(date.year + '-' + date.month + '-01 03:00:00'),
                    value: el.value || 0
                });
            })

            ret.sort(sort_by('client'));
            return ret;
        })
    },

    _readProInfa: async function (file: any, schema: any) {
        return await readXlsxFile(file, {schema}).then(async ({rows, errors}) => {
            if (errors && errors.length>0) {
                console.log("Ocorreu um erro! Formato de arquivo incorreto ou sem dados encontrados...")
                console.log(errors)
                return null;
            }

            let ret: any  = [];
            let contract  = '';
            let item: any = {code: '', unit: '', data: []};
            rows.map((el: any, index: number) => {
                let value: number = parseFloat(el.value) || 0;
                let dateTime = new Date(el.date);
                    dateTime.setHours(dateTime.getHours()+3);

                if ((el.code && contract!==el.code) || contract==='') {
                    if (contract!=='') ret.push(item);

                    item      = {code: '', data: []};
                    contract  = el.code;
                    item.code = el.code;
                    item.unit = el.unit;
                    item.data.push({
                        date: dateTime,
                        value: value
                    });
                } else {
                    item.data.push({
                        date: dateTime,
                        value: value
                    });
                }

                if (rows.length===(index+1)) ret.push(item);
            })

            ret.sort(sort_by('unit'));
            return ret;
        })
    }
}

export default readXlsx;