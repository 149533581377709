import React from 'react';
import Helmet from 'react-helmet';
import './styles.css';

// Components
import Menu from '~/components/default/menu';
import Header from '~/components/default/header';
import Footer from '~/components/default/footer';

interface TitleProps {
    pageTitle: string;
    title: string;
    subtitle?: string;
    className?: string;
}

const Admin: React.FC<TitleProps> = (props) => {
    return (
        <>
            <Helmet title={props.pageTitle} />

            <section className={`container${props.className ? ' ' + props.className : ''}`}>
                <Menu />

                <div className="content">
                    <Header title={props.title} subtitle={props.subtitle} />

                    <main>
                        { props.children }
                    </main>

                    <Footer />
                </div>
            </section>
        </>
    );
}

export default Admin;