import React from 'react';
import loaging from '~/assets/images/loading-single.svg';
import './styles.css';

const LoadingSingle = () => {
    return (
        <div className="loading-single">
            <img src={loaging} alt="Loading..." />
        </div>
    )
}

export default LoadingSingle;