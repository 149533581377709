interface ENV {
    stage: string;
    host: string;

    crypto: {
        web: string;
        server: string;
    }
}

const env: ENV = {
    stage: process.env.REACT_APP_STAGE || '',
    host: process.env.REACT_APP_HOST || '',

    crypto: {
        web: process.env.REACT_APP_CRYPTO_WEB || '',
        server: process.env.REACT_APP_CRYPTO_SERVER || ''
    }

}

export default env;