import React, { useContext, useEffect, useState } from 'react';
import ReactTooltip from "react-tooltip";
import { FiBook, FiCreditCard, FiDownload, FiFileText, FiLoader, FiServer, FiThermometer } from 'react-icons/fi';
import { GeneralContext, ModalContext } from '~/contexts';
import { FormProinfa, FormCCEEFees, FormCCEEFeesPayment, FormBackupEnergy, FormLiquid } from '~/forms';
import './styles.css';

import api from '~/services/api';
import env from '~/utils/env';

// Import Components
import AdminDesign from '~/components/admin';
import ClientBox from '~/components/parts/ClientsBox';
import {LoadingSingle} from '~/components/default/loading';
import { FaIndustry, FaTimes } from 'react-icons/fa';
import sort_by from '~/utils/sort';

const Clients: React.FC<any> = ({ match }) => {
    const {setIsOpen, setTitleModal, setContentModal, setFooterModal} = useContext(ModalContext);
    const {user}                        = useContext(GeneralContext);
    const [ready, setReady]             = useState(false);
    const [downClient, setDownClient]   = useState(false);
    const [downData, setDownData]       = useState(false);
    const [clientsList, setClientsList] = useState([]);
    const [clients, setClients]         = useState([]);
    const [tooltip, setTooltip]         = useState('');
    const [search, setSearch]           = useState('');

    function openModal(modal: any, data?: any) {
        switch (modal) {
            case 'uploadProinfa':
                setTitleModal('Upload do Proinfa');
                setContentModal(<FormProinfa />);
                break;

            case 'uploadCCEEFees':
                setTitleModal('Upload da Taxa CCEE');
                setContentModal(<FormCCEEFees />);
                break;

            case 'uploadCCEEFeesPayment':
                setTitleModal('Upload dos Boleto Taxa CCEE');
                setContentModal(<FormCCEEFeesPayment />);
                break;

            case 'uploadBackupEnergy':
                setTitleModal('Upload da Energia de Reserva');
                setContentModal(<FormBackupEnergy />);
                break;

            case 'uploadLiquid':
                setTitleModal('Upload da Garantia Financeira');
                setContentModal(<FormLiquid />);
                break;

            default:
                setTitleModal('');
                setContentModal(<></>);
                setFooterModal(<></>);
                break;
        }

        setIsOpen(true)
    }

    function downloadClients () {
        setDownClient(true)

        api.put(`${env.host}/save/ccee`).then(resp => {
            let units = resp.data.units.sort(sort_by('name'));
            setClientsList(units)
            setClients(units)
            setDownClient(false)
        })
    }

    function downloadData () {
        setDownData(true)

        api.put(`${env.host}/save/ccee/data?current=1`).then(resp => {
            setDownData(false)
        })
    }

    function onChangeSearch (event: any) {
        let data = event.target.value;
        let clientsFilter = clientsList.filter((el: any) =>
            el.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0
            ? true : false
        )

        setSearch(data)
        setClients(clientsFilter);
    }

    useEffect(() => {
        api.get(`${env.host}/ccee`, {headers: { Authorization: user.token }}).then(resp => {
            let units = resp.data.sort(sort_by('name'));
            setClientsList(units)
            setClients(units)
            setReady(true)
        })
    }, []);

    return (
        <AdminDesign pageTitle="Gerenciamento de Clientes - API Energizou" title="Clientes" subtitle="Gestão de Clientes" className="cl">
            {
                ready ? (
                    <>
                        <div className="headerLine">
                            <div className="buttons">
                                <button
                                    type="button"
                                    className={`clients ${downClient ? 'load' : ''}`}
                                    onClick={() => downloadClients()}
                                    onMouseOver={() => setTooltip('Atualizar Clientes')}
                                    disabled={downClient}
                                    data-for="tooltip_clients"
                                    data-tip
                                >
                                    { !downClient ? <FaIndustry size="16" /> : <FiLoader size="16" color="#FFF" strokeWidth="3" /> }
                                </button>

                                <button
                                    type="button"
                                    className={`download ${downData ? 'load' : ''}`}
                                    onClick={() => downloadData()}
                                    onMouseOver={() => setTooltip('Atualizar Dados')}
                                    disabled={downData}
                                    data-for="tooltip_clients"
                                    data-tip
                                >
                                    { !downData ? <FiDownload size="16" /> : <FiLoader size="16" color="#FFF" strokeWidth="3" /> }
                                </button>

                                <button
                                    type="button"
                                    className="proinfa"
                                    onClick={() => openModal('uploadProinfa')}
                                    onMouseOver={() => setTooltip('Upload da Proinfa')}
                                    data-for="tooltip_clients"
                                    data-tip
                                >
                                    <FiBook size={16} />
                                </button>

                                <button
                                    type="button"
                                    className="fees"
                                    onClick={() => openModal('uploadCCEEFees')}
                                    onMouseOver={() => setTooltip('Upload da Taxa CCEE')}
                                    data-for="tooltip_clients"
                                    data-tip
                                >
                                    <FiThermometer size={16} />
                                </button>

                                <button
                                    type="button"
                                    className="feesPayment"
                                    onClick={() => openModal('uploadCCEEFeesPayment')}
                                    onMouseOver={() => setTooltip('Upload dos Boletos CCEE')}
                                    data-for="tooltip_clients"
                                    data-tip
                                >
                                    <FiFileText size={16} />
                                </button>

                                <button
                                    type="button"
                                    className="bkpEnergy"
                                    onClick={() => openModal('uploadBackupEnergy')}
                                    onMouseOver={() => setTooltip('Upload da Energia de Reserva')}
                                    data-for="tooltip_clients"
                                    data-tip
                                >
                                    <FiServer size={16} />
                                </button>

                                <button
                                    type="button"
                                    className="salesOff"
                                    onClick={() => openModal('uploadLiquid')}
                                    onMouseOver={() => setTooltip('Upload da Garantia Financeira')}
                                    data-for="tooltip_clients"
                                    data-tip
                                >
                                    <FiCreditCard size={16} />
                                </button>
                            </div>

                            <div className="searchBox">
                                <input type="text" name="search" id="search" placeholder='Filtrar Unidades...' value={search} onChange={onChangeSearch} />
                                { search!=='' && <button onClick={() => {
                                    setSearch('')
                                    setClients(clientsList)
                                }}>
                                    <FaTimes />
                                </button> }
                            </div>
                        </div>

                        <div className="clients">
                            {
                                clients.map((el: any) => <ClientBox
                                    key={`client_${el.id}`}
                                    setTooltip={setTooltip}
                                    {...el}
                                />)
                            }
                        </div>

                        <ReactTooltip
                            id="tooltip_clients"
                            place="bottom"
                            effect="solid"
                            type="info"
                        >
                            { tooltip }
                        </ReactTooltip>
                    </>
                ) : <LoadingSingle />
            }
        </AdminDesign>
    );
}

export default Clients;