import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { GeneralContext } from '~/contexts';

const Logout = () => {
    const history  = useHistory()
    const {logout} = useContext(GeneralContext)
    logout()
    history.push('/login')

    return null;
}

export default Logout;