import React, { useState, ChangeEvent, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import { FaTimes, FaLock, FaEnvelopeOpen } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';
import cryptos from '~/utils/cryptos';
import './styles.css';

// Elements
import logo from '~/assets/images/logo.svg';

const initialState = {mail: '', password: ''};

const Login: React.FC<any> = ({ match }) => {
    const { text }              = match.params;
    const history               = useHistory();
    const {logged, login}       = useContext(GeneralContext);
    const [values, setValues]   = useState(initialState);
    const [message, setMessage] = useState<string>();
    const [errors, setErrors]   = useState<String[]>([]);

    // Add Login class
    document.querySelector('#root')?.classList.add('login');

    useEffect(() => {
        if (logged) history.push('/')
        text && setMessage(Buffer.from(text, 'base64').toString('ascii'))
    }, [text, errors])

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        const {name, value} = event.target;

        setValues({
            ...values,
            [name]: value
        })
    }

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (values.mail==='' || values.password==='') {
            var errArr = [];

            if (values.mail==='') errArr.push('O campo de email está vazio');
            if (values.password==='') errArr.push('O campo de senha está vazio');

            setErrors(errArr);
            return;
        }

        api.post('login', values).then((resp: any) => {
            const user = cryptos.decryptServer(resp.data);
            login(user)
            document.querySelector('#root')?.classList.remove('login')
            return history.push('/');
        }).catch((err: any) => {
            const { status } = err.response;
            if (status===400) {
                const error = err.response.data.errors;
                setErrors(error);
            }
        });

        setValues({ ...values, password: '' });
    }

    function treatErrors() {
        if (message!=='') {
            history.replace('/login');
            setMessage('');
        }

        return (
            <div className="boxErrors">
                <div className="errors">
                    { errors.map(el => <span key={`error_${el}`}>{ el }</span>) }
                </div>

                <button type="button" onClick={() => { setErrors([]) }} className="close">
                    <FaTimes size="16" />
                </button>
            </div>
        )
    }

    function treatMessage() {
        return (
            <div className="boxMessage">
                <div className="message">
                    { message }
                </div>

                <button type="button" onClick={() => { setMessage(''); history.replace('/login') }} className="close">
                    <FaTimes size="16" />
                </button>
            </div>
        )
    }

    return (
        <>
            <Helmet title="Login - Clientes Energizou" />

            <main className="login-page">
                <Link to="/" className="logo">
                    <img src={logo} alt="Energizou"/>
                </Link>

                { Array.isArray(errors) && errors.length>0 && treatErrors() }
                { !errors.length && message && treatMessage() }

                <div className="body-box">
                    <form method="post" onSubmit={onSubmit} autoComplete="off">
                        <div className="input-group has-feedback">
                            <input id="mail" name="mail" type="mail" placeholder="Email" value={values.mail} onChange={onChange} />
                            <FaEnvelopeOpen className="icons form-control-feedback" size="18"></FaEnvelopeOpen>
                        </div>

                        <div className="input-group has-feedback">
                            <input id="password" name="password" type="password" placeholder="Senha" value={values.password} onChange={onChange} />
                            <FaLock className="icons form-control-feedback" size="18"></FaLock>
                        </div>

                        <div className="input-group">
                            <button id="btnLogin" type="submit">Entrar</button>
                        </div>
                    </form>

                    <Link to="/forgot-password" className="forgot">
                        Esqueci minha senha
                    </Link>
                </div>

                <div className="copyright">
                    Copyright © 2020 - Todos os direitos reservados
                </div>
            </main>
        </>
    );
}

export default Login;